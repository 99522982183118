<template>
  <div class="statistics">
      <div class="s-main">
          <div class="sm-title"><Icon type="ios-stats" /><p>访问统计</p></div>
          <div class="line"></div>
          <div class="sm-total-box">
            <div class="total-item">
              <div class="ti-label">访问总人数</div>
              <div class="ti-info"><span>{{statTotal}}</span>人</div>
            </div>
            <div class="total-item">
              <div class="ti-label">完成总人数</div>
              <div class="ti-info"><span>{{finTotal}}</span>人</div>
            </div>
          </div>
          <div class="title">省访问人数</div>
          <div class="province-total-box">
            <div class="pt-item" v-for="(item,index) in statList" :key="index">
              <div class="pt-label">{{item.name}}</div>
              <div class="pt-num">访问{{item.count}}人</div>
              <div class="pt-num">完成{{item.finish_count}}人</div>
            </div>
          </div>
          <div class="title" style="margin-top:20px">市访问人数</div>
          <Select v-model="currentPro" @on-change="pickPro" style="width:300px">
            <Option v-for="item in cityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
          <div class="province-total-box" style="margin-top:10px">
            <div class="pt-item" v-for="(pitem,index) in currentProList" :key="index">
              <div class="pt-label">{{pitem.name}}</div>
              <div class="pt-num">访问{{pitem.count}}人</div>
              <div class="pt-num">完成{{pitem.finish_count}}人</div>
            </div>
          </div>
      </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  data() {
    return {
      statTotal: 0,
      doneTotal: 0,
      statList: [],
      firstFlag: true,
      cityList: [],
      currentPro: '',
      currentProList: []
    };
  },
  filters: {
    
  },
  created() {},
  mounted() {
    this.getStat()
  },
  destory() {},
  methods: {
    async getStat(pid) {
      let res = {}
      if(pid){
        res = await this.$fetch("/sapi/userGameLog/statGameLog", {
          method: "post",
          data: {province:pid}
        });
      }else{
        res = await this.$fetch("/sapi/userGameLog/statGameLog", {
          method: "post",
          data: {}
        });
      }
      if (res) {
        if(this.firstFlag){
          this.firstFlag = false
          let list = res.data
          let total = 0;
          let finishTotal = 0;
          for (let index = 0; index < list.length; index++) {
              const element = list[index];
              total += element.count;
              finishTotal += element.finish_count;
          }
          this.statTotal = total
          this.finTotal = finishTotal
          this.statList = list
          let cityList = []
          list.forEach((val)=>{
            if(val.name != '香港特别行政区' && val.name != '台湾省' && val.name != '天津市' && val.name != '北京市' && val.name != '澳门特别行政区'){
              cityList.push({value:val.province,label:val.name})
            }
          })
          this.cityList = cityList
        }else{
          let plist = res.data
          this.currentProList = plist
        }
      }
    },
    pickPro(e) {
      this.getStat(e)
      // this.projectName = e.vprojectname;
      // this.currentData = e;
      // this.codeUrl = e.qrcode_url;
      // this.status = e.passport_status;
    }
  }
};
</script>

<style scoped lang="less">
body {
  // background: #fff;
}
.swiper-container{
  // height: 100% !important;
  background: #0575e5;
}
// .swiper-wrapper{
//   height: 100%;
// }
// .swiper-pagination{
//   position: fixed;
// }
.statistics{
    .s-main{
        background: #fff;
        max-width: 1200px;
        margin: 0 auto;
        min-height: 100vh;
        padding: 30px;
        .sm-title{
            font-size: 16px;
            display: flex;
            align-items: center;
            p{
                margin-left: 4px;
            }
        }
        .line{
            width: 100%;
            height: 2px;
            background: #0575e5;
            border-radius: 1px;
            margin: 12px auto;
        }
        .sm-total-box{
          margin-bottom: 10px;
          display: flex;
          .total-item{
            display: flex;
            margin-right: 10px;
            .ti-label{
              margin-right: 6px;
              font-weight: bold;
            }
            .ti-info{

            }
          }
        }
        .province-total-box{
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .pt-item{
          width: 120px;
          border-radius: 4px;
          margin: 2px 4px;
          text-align: center;
          border: 1px solid #0575e5;
            .pt-label{
              margin-right: 6px;
            }
            .pt-num{
              font-size: 12px;
              margin-bottom: 2px;
            }
          }
        }
    }
}
</style>